import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer({ style }) {
  

  return (
    <div className="footer_container" style={style}>
      <div className="inner_footer_box">
        <svg
          width="100"
          height="50"
          viewBox="0 0 210 67"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M54.6419 29.8674H71.0099C72.1169 29.8674 73.0353 30.2384 73.7713 30.9744C74.5073 31.7104 74.8783 32.6288 74.8783 33.7358C74.8783 34.8428 74.5073 35.6822 73.7713 36.3939C73.0353 37.1055 72.1169 37.4644 71.0099 37.4644H54.6419C53.5349 37.4644 52.6164 37.0994 51.8805 36.3574C51.1445 35.6214 50.7734 34.7029 50.7734 33.5959C50.7734 32.4889 51.1384 31.6556 51.8805 30.9379C52.6164 30.2262 53.5349 29.8674 54.6419 29.8674ZM62.0321 21.2363C63.2304 21.2363 64.2097 21.6438 64.9639 22.4467C65.7242 23.2496 66.1013 24.2532 66.1013 25.4515V56.1802C66.1013 56.8249 66.229 57.3541 66.4784 57.7677C66.7278 58.1813 67.0745 58.4855 67.5124 58.6679C67.9504 58.8504 68.4187 58.9417 68.9297 58.9417C69.4832 58.9417 69.988 58.8382 70.4503 58.6314C70.9126 58.4246 71.4417 58.3212 72.0378 58.3212C72.6826 58.3212 73.2665 58.6193 73.7957 59.2154C74.3248 59.8114 74.5925 60.6447 74.5925 61.7031C74.5925 62.9926 73.893 64.0509 72.4879 64.8782C71.0829 65.7054 69.5744 66.119 67.9626 66.119C66.9954 66.119 65.9249 66.0399 64.751 65.8757C63.5771 65.7115 62.4701 65.3222 61.436 64.7018C60.402 64.0814 59.5383 63.1264 58.8449 61.8369C58.1515 60.5474 57.8109 58.7774 57.8109 56.5208V25.4454C57.8109 24.2471 58.2123 23.2496 59.0213 22.4406C59.8242 21.6377 60.8278 21.2302 62.026 21.2302L62.0321 21.2363Z"
            fill="white"
          />
          <path
            d="M81.2938 24.685C79.7306 24.685 78.6236 24.4295 77.9788 23.9247C77.3341 23.4198 77.0117 22.5196 77.0117 21.2301V19.9163C77.0117 18.5842 77.3706 17.6719 78.0822 17.1913C78.7939 16.7047 79.8887 16.4675 81.3607 16.4675C82.9726 16.4675 84.0978 16.723 84.7426 17.2278C85.3873 17.7327 85.7097 18.6329 85.7097 19.9163V21.2301C85.7097 22.5683 85.363 23.4746 84.6757 23.9551C83.9823 24.4417 82.857 24.6789 81.2938 24.6789V24.685ZM85.509 61.9037C85.509 63.102 85.1197 64.1056 84.335 64.9085C83.5504 65.7114 82.565 66.1189 81.3668 66.1189C80.1685 66.1189 79.1771 65.7174 78.3985 64.9085C77.6139 64.1056 77.2246 63.102 77.2246 61.9037V33.389C77.2246 32.1908 77.6139 31.1932 78.3985 30.3843C79.1771 29.5814 80.1685 29.1738 81.3668 29.1738C82.565 29.1738 83.5504 29.5753 84.335 30.3843C85.1197 31.1872 85.509 32.1908 85.509 33.389V61.9037Z"
            fill="white"
          />
          <path
            d="M105.668 28.4866C108.29 28.4866 110.583 28.7603 112.541 29.3138C114.494 29.8673 116.014 30.6398 117.097 31.6251C118.18 32.6166 118.721 33.8027 118.721 35.1834C118.721 36.1079 118.447 36.9716 117.894 37.7745C117.34 38.5774 116.537 38.9849 115.479 38.9849C114.743 38.9849 114.135 38.8815 113.648 38.6747C113.162 38.4679 112.742 38.2003 112.371 37.8779C112 37.5556 111.562 37.2575 111.057 36.9838C110.595 36.7101 109.895 36.4911 108.952 36.3269C108.01 36.1627 107.304 36.0836 106.848 36.0836C104.5 36.0836 102.511 36.5884 100.875 37.6042C99.2388 38.62 97.9858 39.9886 97.1099 41.716C96.234 43.4434 95.7961 45.4324 95.7961 47.689C95.7961 49.9456 96.2462 51.8677 97.1403 53.5951C98.0405 55.3225 99.2813 56.6911 100.869 57.7069C102.456 58.7165 104.287 59.2275 106.361 59.2275C107.511 59.2275 108.502 59.1606 109.33 59.0207C110.157 58.8808 110.85 58.674 111.398 58.4003C112.042 58.0292 112.62 57.6399 113.125 57.2263C113.63 56.8127 114.39 56.6059 115.406 56.6059C116.604 56.6059 117.523 56.983 118.167 57.7433C118.812 58.5037 119.135 59.4343 119.135 60.5413C119.135 61.6483 118.49 62.7371 117.2 63.6799C115.911 64.6226 114.22 65.383 112.128 65.9608C110.029 66.5325 107.766 66.8245 105.327 66.8245C101.69 66.8245 98.5393 65.9851 95.8691 64.3064C93.1988 62.6276 91.1369 60.3345 89.6893 57.4331C88.2416 54.5318 87.5117 51.2898 87.5117 47.7012C87.5117 43.93 88.2842 40.6029 89.8231 37.7259C91.3619 34.8489 93.5091 32.5923 96.2462 30.9561C98.9833 29.326 102.128 28.5048 105.674 28.5048L105.668 28.4866Z"
            fill="white"
          />
          <path
            d="M125.211 66.1187C124.012 66.1187 123.021 65.7173 122.242 64.9083C121.458 64.1054 121.068 63.1018 121.068 61.9036V19.2288C121.068 18.0306 121.458 17.0331 122.242 16.2241C123.021 15.4212 124.012 15.0137 125.211 15.0137C126.409 15.0137 127.394 15.4212 128.179 16.2241C128.963 17.027 129.353 18.0306 129.353 19.2288V61.9036C129.353 63.1018 128.963 64.1054 128.179 64.9083C127.394 65.7112 126.409 66.1187 125.211 66.1187ZM147.168 29.1798C148.227 29.1798 149.145 29.6177 149.93 30.4936C150.714 31.3695 151.104 32.221 151.104 33.0483C151.104 34.1553 150.55 35.1893 149.449 36.1564L128.665 55.2797L128.319 45.7485L144.133 30.4875C145.009 29.6116 146.019 29.1737 147.174 29.1737L147.168 29.1798ZM148.202 66.0518C147.004 66.0518 145.994 65.5896 145.167 64.6711L132.394 51.1376L138.677 45.341L151.104 58.8745C151.98 59.799 152.418 60.8331 152.418 61.9827C152.418 63.1322 151.943 64.1602 151 64.9205C150.058 65.6808 149.127 66.0579 148.202 66.0579V66.0518Z"
            fill="white"
          />
          <path
            d="M170.78 66.8057C166.869 66.8057 163.469 65.9907 160.592 64.3545C157.715 62.7244 155.507 60.4982 153.962 57.6942C152.417 54.8841 151.65 51.709 151.65 48.1629C151.65 44.0207 152.49 40.4868 154.169 37.5611C155.847 34.6415 158.043 32.3971 160.762 30.8278C163.475 29.2646 166.358 28.48 169.393 28.48C171.741 28.48 173.961 28.9605 176.059 29.9276C178.152 30.8947 180.007 32.2207 181.619 33.8995C183.231 35.5782 184.508 37.5246 185.451 39.7326C186.394 41.9405 186.862 44.2884 186.862 46.7761C186.819 47.8831 186.381 48.7772 185.548 49.4706C184.715 50.1641 183.754 50.5047 182.647 50.5047H156.273L154.205 43.601H179.545L178.024 44.9818V43.1205C177.933 41.7824 177.458 40.5902 176.613 39.5258C175.761 38.4674 174.703 37.628 173.438 37.0076C172.173 36.3872 170.822 36.077 169.399 36.077C167.976 36.077 166.729 36.2595 165.531 36.6305C164.332 37.0015 163.298 37.622 162.422 38.4918C161.547 39.3676 160.859 40.5416 160.354 42.0135C159.85 43.4855 159.594 45.3528 159.594 47.6033C159.594 50.0911 160.111 52.1956 161.145 53.923C162.179 55.6505 163.517 56.9643 165.147 57.8584C166.784 58.7525 168.517 59.2026 170.36 59.2026C172.063 59.2026 173.42 59.0627 174.435 58.789C175.445 58.5153 176.266 58.1808 176.887 57.7854C177.507 57.3961 178.067 57.0616 178.578 56.7818C179.405 56.3682 180.189 56.1614 180.925 56.1614C181.941 56.1614 182.774 56.5081 183.45 57.1954C184.119 57.8888 184.453 58.6917 184.453 59.6102C184.453 60.851 183.809 61.9823 182.519 62.992C181.321 64.0078 179.642 64.8898 177.477 65.6501C175.311 66.4104 173.079 66.7875 170.78 66.7875V66.8057Z"
            fill="white"
          />
          <path
            d="M189.769 29.8674H206.131C207.238 29.8674 208.156 30.2384 208.892 30.9744C209.628 31.7104 209.993 32.6288 209.993 33.7358C209.993 34.8428 209.628 35.6822 208.892 36.3939C208.156 37.1055 207.238 37.4644 206.131 37.4644H189.769C188.668 37.4644 187.743 37.0994 187.007 36.3574C186.271 35.6214 185.9 34.7029 185.9 33.5959C185.9 32.4889 186.265 31.6556 187.007 30.9379C187.743 30.2262 188.662 29.8674 189.769 29.8674ZM197.159 21.2363C198.357 21.2363 199.331 21.6438 200.091 22.4467C200.851 23.2496 201.228 24.2532 201.228 25.4515V56.1802C201.228 56.8249 201.35 57.3541 201.605 57.7677C201.855 58.1813 202.201 58.4855 202.639 58.6679C203.077 58.8504 203.546 58.9417 204.057 58.9417C204.61 58.9417 205.115 58.8382 205.577 58.6314C206.04 58.4246 206.569 58.3212 207.165 58.3212C207.81 58.3212 208.393 58.6193 208.929 59.2154C209.458 59.8114 209.719 60.6447 209.719 61.7031C209.719 62.9926 209.014 64.0509 207.615 64.8782C206.21 65.7054 204.701 66.119 203.096 66.119C202.128 66.119 201.058 66.0399 199.884 65.8757C198.71 65.7115 197.609 65.3222 196.569 64.7018C195.529 64.0814 194.671 63.1264 193.978 61.8369C193.285 60.5474 192.944 58.7774 192.944 56.5208V25.4454C192.944 24.2471 193.345 23.2496 194.154 22.4406C194.963 21.6317 195.961 21.2302 197.159 21.2302V21.2363Z"
            fill="white"
          />
          <path
            d="M70.7047 0.780079C69.6464 -0.260026 67.9493 -0.260026 66.891 0.780079L43.1389 24.4896L30.6576 12.0084C29.6053 10.9561 27.8962 10.9561 26.8439 12.0084C25.7916 13.0606 25.7916 14.7698 26.8439 15.8221L41.2533 30.1889C41.746 30.7059 42.4272 30.9918 43.1389 30.9857C43.8627 30.9979 44.5622 30.7059 45.067 30.1889L70.7047 4.55122C71.7448 3.52936 71.7631 1.86276 70.7412 0.816574C70.729 0.804409 70.7169 0.792244 70.7047 0.780079Z"
            fill="#57BEC0"
          />
          <path
            d="M33.9728 39.7743C33.03 37.615 31.7283 35.6321 30.1165 33.9108C28.5168 32.2381 26.6069 30.8878 24.5023 29.9328C22.41 28.9779 20.1412 28.4913 17.842 28.5095C14.7947 28.4913 11.796 29.3063 9.17447 30.8574C6.39477 32.4631 4.10775 34.7927 2.55672 37.6028C0.841456 40.5285 0.00207244 44.0868 0.00207244 48.235C-0.0465875 51.5804 0.762384 54.8832 2.34991 57.8271C3.89487 60.6433 6.20621 62.9668 9.01024 64.53C11.8568 66.1662 15.2934 66.9995 19.2288 66.9995C21.5158 66.9934 23.7785 66.592 25.9317 65.8256C28.1093 65.0714 29.7029 64.232 30.9984 63.1858C32.294 62.1396 32.9692 61.0083 32.9692 59.7492C32.9692 58.8368 32.6103 57.967 31.9656 57.3223C31.2843 56.6532 30.3659 56.2943 29.4109 56.3187C28.5959 56.3491 27.799 56.5498 27.0631 56.9026L25.3478 57.9062C24.5997 58.3806 23.7785 58.7152 22.9209 58.9098C21.5767 59.2322 20.1959 59.3721 18.8152 59.3295C16.9844 59.3477 15.1779 58.8855 13.5782 57.9914C11.9116 57.0607 10.5187 55.7043 9.55766 54.056C8.94941 53.0341 8.52972 51.8906 8.28034 50.6194H31.1687C32.2271 50.6194 33.249 50.2483 34.0579 49.5732C34.8851 48.9102 35.3717 47.9127 35.3961 46.8482C35.3961 44.4152 34.9095 42.0127 33.9728 39.7682V39.7743ZM10.8107 38.5578C11.6865 37.6819 12.7631 37.0311 13.9492 36.6722C15.2144 36.2951 16.5282 36.1126 17.842 36.1248C19.2471 36.1187 20.64 36.4289 21.9051 37.0433C23.1277 37.6515 24.2104 38.5091 25.0863 39.5553C25.9013 40.6076 26.394 41.8728 26.5096 43.1987V43.661H8.35941C8.4689 43.1257 8.60271 42.5966 8.76086 42.0674C9.16838 40.7536 9.86787 39.5492 10.8107 38.5517V38.5578Z"
            fill="#57BEC0"
          />
        </svg>
        <div className="footer_links">
          <Link to={"/privacy"}>Datenschutzrichtlinie</Link>
          <span className="text-white">-</span>
          <Link to="/agb#Stornierung">Widerrufsrecht</Link>
          <span className="text-white">-</span>
          <Link to="/imprint">Impressum</Link>
          <span className="text-white">-</span>
          <Link to={"/agb"}>AGB</Link>
        </div>
        <div className="socials">
          <div className="d-flex gap-3 w-100">
            <svg
              width="23"
              height="27"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_305_7167)">
                <path
                  d="M27 13C27 5.81953 20.9566 0 13.5 0C6.04336 0 0 5.81953 0 13C0 20.1805 6.04336 26 13.5 26C13.5791 26 13.6582 26 13.7373 25.9949V15.8793H10.8369V12.6242H13.7373V10.2273C13.7373 7.44961 15.4986 5.93633 18.0721 5.93633C19.3061 5.93633 20.366 6.02266 20.6719 6.06328V8.96797H18.9C17.5025 8.96797 17.2283 9.60781 17.2283 10.5473V12.6191H20.577L20.1393 15.8742H17.2283V25.4973C22.8709 23.9383 27 18.9363 27 13Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_305_7167">
                  <rect width="27" height="26" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="23"
              height="27"
              viewBox="0 0 28 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_305_7172)">
                <path
                  d="M14 16.2686C12.4143 16.2686 11.1289 15.029 11.1289 13.5C11.1289 11.971 12.4143 10.7314 14 10.7314C15.5857 10.7314 16.8711 11.971 16.8711 13.5C16.8711 15.029 15.5857 16.2686 14 16.2686Z"
                  fill="white"
                />
                <path
                  d="M14 0.316406C21.5508 0.316406 27.6719 6.21891 27.6719 13.5C27.6719 20.7811 21.5508 26.6836 14 26.6836C6.44924 26.6836 0.328125 20.7811 0.328125 13.5C0.328125 6.21891 6.44924 0.316406 14 0.316406ZM5.55308 16.8507C5.6187 18.1041 5.98396 19.3401 6.9312 20.2438C7.88763 21.1561 9.17547 21.4961 10.4878 21.5587H17.5121C18.8246 21.4961 20.1123 21.1563 21.0687 20.2438C22.016 19.3401 22.3812 18.1041 22.4469 16.8507V10.1493C22.3812 8.89587 22.016 7.65988 21.0687 6.75612C20.1123 5.84381 18.8245 5.50389 17.5121 5.44129H10.4879C9.17536 5.50389 7.88769 5.84365 6.93126 6.75612C5.98396 7.65988 5.61876 8.89587 5.55313 10.1493L5.55308 16.8507Z"
                  fill="white"
                />
                <path
                  d="M10.5763 6.96826C12.2873 6.92301 15.7118 6.92301 17.4227 6.96826C18.3131 6.99183 19.3225 7.20557 19.9568 7.85894C20.6161 8.53811 20.8652 9.35866 20.8904 10.2766C20.9348 11.8877 20.8904 16.7235 20.8904 16.7235C20.8615 17.6413 20.6161 18.462 19.9568 19.1411C19.3225 19.7947 18.3131 20.0083 17.4227 20.0318C15.7118 20.0771 12.2873 20.0771 10.5763 20.0318C9.68594 20.0083 8.67657 19.7945 8.04219 19.1411C7.38299 18.462 7.13389 17.6414 7.10862 16.7235V10.2766C7.13389 9.35866 7.38299 8.53811 8.04219 7.85894C8.67679 7.20535 9.68615 6.99183 10.5763 6.96826ZM13.9997 17.7913C14.8799 17.7913 15.7403 17.5396 16.4721 17.0681C17.204 16.5966 17.7744 15.9264 18.1112 15.1422C18.448 14.3581 18.5361 13.4953 18.3644 12.6629C18.1927 11.8304 17.7689 11.0658 17.1465 10.4657C16.5241 9.86552 15.7312 9.45682 14.8679 9.29124C14.0047 9.12566 13.1099 9.21064 12.2967 9.53544C11.4836 9.86023 10.7885 10.4103 10.2995 11.1159C9.81054 11.8216 9.54955 12.6513 9.54955 13.5C9.54955 14.6382 10.0184 15.7297 10.853 16.5344C11.6876 17.3392 12.8195 17.7913 13.9997 17.7913ZM9.53062 10.0946C9.70665 10.0945 9.87871 10.0442 10.025 9.94982C10.1714 9.85549 10.2854 9.72144 10.3528 9.56461C10.4201 9.40778 10.4377 9.23522 10.4033 9.06875C10.3689 8.90228 10.2842 8.74937 10.1597 8.62936C10.0352 8.50936 9.87661 8.42764 9.70396 8.39454C9.53132 8.36144 9.35237 8.37845 9.18975 8.44341C9.02713 8.50838 8.88814 8.61838 8.79035 8.75952C8.69256 8.90065 8.64037 9.06658 8.64037 9.23631C8.64037 9.34904 8.66339 9.46066 8.70814 9.5648C8.75288 9.66894 8.81846 9.76356 8.90113 9.84326C8.9838 9.92296 9.08194 9.98618 9.18995 10.0293C9.29796 10.0724 9.41372 10.0946 9.53062 10.0946Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_305_7172">
                  <rect
                    width="28"
                    height="27"
                    fill="white"
                    transform="matrix(-1 0 0 1 28 0)"
                  />
                </clipPath>
              </defs>
            </svg>
            <svg
              width="23"
              height="26"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_305_7169)">
                <path
                  d="M11.8184 15.4355L16.2097 13L11.8184 10.5645V15.4355Z"
                  fill="white"
                />
                <path
                  d="M13.5 0C6.0453 0 0 5.8214 0 13C0 20.1786 6.0453 26 13.5 26C20.9547 26 27 20.1786 27 13C27 5.8214 20.9547 0 13.5 0ZM21.9354 13.0133C21.9354 13.0133 21.9354 15.6497 21.5881 16.9211C21.3935 17.6169 20.8237 18.1656 20.1011 18.3529C18.7809 18.6875 13.5 18.6875 13.5 18.6875C13.5 18.6875 8.23295 18.6875 6.89893 18.3396C6.17631 18.1523 5.60653 17.6034 5.41187 16.9076C5.06435 15.6497 5.06435 13 5.06435 13C5.06435 13 5.06435 10.3637 5.41187 9.09242C5.60632 8.39656 6.19011 7.8344 6.89893 7.64714C8.21915 7.3125 13.5 7.3125 13.5 7.3125C13.5 7.3125 18.7809 7.3125 20.1011 7.66043C20.8237 7.84769 21.3935 8.39656 21.5881 9.09242C21.9494 10.3637 21.9354 13.0133 21.9354 13.0133Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_305_7169">
                  <rect width="27" height="26" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div className="copywrite">
        <div className="copywrite_inner_box">
          <p>Copyright © - 2023</p>
        </div>
      </div>
    </div>
  );
}
