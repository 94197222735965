import React from "react";
import { Modal } from "react-bootstrap";
import { formatDateToDDMMYYYY } from "../../../utils/utils";
import { extractOrderTime } from "../../../utils/utils";
import "./OrderModal.css";

export default function OrderModal({ handleHide, show, data }) {
  if (!data) {
    return;
  }
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="create_event_modal"
      show={show}
      onHide={handleHide}
      backdropClassName="order_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs-2">
          {data.code}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-3">
        <div className="order_modal_details row d-flex justify-content-center">
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 11 11"
                fill="none"
              >
                <g clip-path="url(#clip0_519_5699)">
                  <path
                    d="M3.98653 6.27054C3.98653 6.40987 3.87253 6.52384 3.73323 6.52384H2.46668C2.32738 6.52384 2.21338 6.40984 2.21338 6.27054V5.42396C2.21338 5.28463 2.32738 5.17065 2.46668 5.17065H3.73323C3.87256 5.17065 3.98653 5.28466 3.98653 5.42396V6.27054Z"
                    fill="#444790"
                  />
                  <path
                    d="M3.98653 8.49491C3.98653 8.63424 3.87253 8.74821 3.73323 8.74821H2.46668C2.32738 8.74821 2.21338 8.63421 2.21338 8.49491V7.64832C2.21338 7.50899 2.32738 7.39502 2.46668 7.39502H3.73323C3.87256 7.39502 3.98653 7.50902 3.98653 7.64832V8.49491Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.40062 6.27054C6.40062 6.40987 6.28662 6.52384 6.14732 6.52384H4.88074C4.74141 6.52384 4.62744 6.40984 4.62744 6.27054V5.42396C4.62744 5.28463 4.74144 5.17065 4.88074 5.17065H6.14732C6.28665 5.17065 6.40062 5.28466 6.40062 5.42396V6.27054Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.40062 8.49491C6.40062 8.63424 6.28662 8.74821 6.14732 8.74821H4.88074C4.74141 8.74821 4.62744 8.63421 4.62744 8.49491V7.64832C4.62744 7.50899 4.74144 7.39502 4.88074 7.39502H6.14732C6.28665 7.39502 6.40062 7.50902 6.40062 7.64832V8.49491Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.78392 6.27054C8.78392 6.40987 8.66992 6.52384 8.53062 6.52384H7.26405C7.12471 6.52384 7.01074 6.40984 7.01074 6.27054V5.42396C7.01074 5.28463 7.12474 5.17065 7.26405 5.17065H8.53062C8.66995 5.17065 8.78392 5.28466 8.78392 5.42396V6.27054Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.78392 8.49491C8.78392 8.63424 8.66992 8.74821 8.53062 8.74821H7.26405C7.12471 8.74821 7.01074 8.63421 7.01074 8.49491V7.64832C7.01074 7.50899 7.12474 7.39502 7.26405 7.39502H8.53062C8.66995 7.39502 8.78392 7.50902 8.78392 7.64832V8.49491Z"
                    fill="#444790"
                  />
                  <path
                    d="M2.84595 3.27604C2.69461 3.27604 2.5708 3.15223 2.5708 3.00087V1.51175C2.5708 1.36038 2.69461 1.23657 2.84595 1.23657H3.41837C3.5697 1.23657 3.69354 1.36038 3.69354 1.51175V3.00087C3.69354 3.1522 3.56973 3.27604 3.41837 3.27604H2.84595Z"
                    fill="#444790"
                  />
                  <path
                    d="M7.61108 3.27604C7.45975 3.27604 7.33594 3.15223 7.33594 3.00087V1.51175C7.33594 1.36038 7.45975 1.23657 7.61108 1.23657H8.1835C8.33484 1.23657 8.45868 1.36038 8.45868 1.51175V3.00087C8.45868 3.1522 8.33487 3.27604 8.1835 3.27604H7.61108Z"
                    fill="#444790"
                  />
                  <path
                    d="M9.90619 2.36206C9.90619 2.36206 9.45188 2.36206 9.10521 2.36206C9.05944 2.36206 8.97476 2.36206 8.97476 2.46757V2.93859C8.97476 3.38829 8.72568 3.75416 8.15919 3.75416H7.6322C7.09506 3.75416 6.81662 3.38829 6.81662 2.93859L6.81665 2.49217C6.81665 2.40783 6.75773 2.36206 6.68744 2.36206C6.0164 2.36206 5.05587 2.36206 4.36102 2.36206C4.3094 2.36206 4.21128 2.36206 4.21128 2.49568V2.93859C4.21128 3.38829 3.98705 3.75416 3.39571 3.75416H2.86872C2.2139 3.75416 2.05315 3.38829 2.05315 2.93859V2.51326C2.05315 2.39377 1.94558 2.36206 1.88702 2.36206C1.5444 2.36206 1.12174 2.36206 1.12174 2.36206C0.975552 2.36206 0.855957 2.48166 0.855957 2.82475V9.92675C0.855957 9.87603 0.975552 9.99563 1.12174 9.99563H9.90617C10.0524 9.99563 10.172 9.87603 10.172 9.92675V2.82475C10.172 2.48166 10.0524 2.36206 9.90619 2.36206ZM9.58134 9.1392C9.58134 9.28539 9.46175 9.40499 9.31556 9.40499H1.71238C1.56619 9.40499 1.4466 9.28539 1.4466 9.1392V4.73847C1.4466 4.59228 1.56619 4.47269 1.71238 4.47269H9.31556C9.46175 4.47269 9.58134 4.59228 9.58134 4.73847V9.1392Z"
                    fill="#444790"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_519_5699">
                    <rect
                      width="9.31599"
                      height="9.31599"
                      fill="white"
                      transform="translate(0.855957 0.958252)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Bestelldatum:{" "}
            </p>
            <p className="col-6 _value">
              {formatDateToDDMMYYYY(data.orderDate)}
            </p>
          </div>
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 11 11"
                fill="none"
              >
                <g clip-path="url(#clip0_519_5681)">
                  <path
                    d="M8.98531 2.19288C8.03785 1.24543 6.77801 0.723633 5.43818 0.723633C4.09835 0.723633 2.8385 1.24543 1.89105 2.19288C0.943596 3.14034 0.421875 4.40001 0.421875 5.73994C0.421875 7.07987 0.943596 8.33954 1.89105 9.28699C2.8385 10.2344 4.09835 10.7562 5.43818 10.7562C6.77801 10.7562 8.03785 10.2344 8.98531 9.28699C9.93276 8.33954 10.4545 7.07987 10.4545 5.73994C10.4545 4.40001 9.93276 3.14034 8.98531 2.19288ZM5.43818 9.913C3.1372 9.913 1.26502 8.04099 1.26502 5.73994C1.26502 3.43888 3.1372 1.56687 5.43818 1.56687C7.73916 1.56687 9.61134 3.43888 9.61134 5.73994C9.61134 8.04099 7.73916 9.913 5.43818 9.913Z"
                    fill="#444790"
                  />
                  <path
                    d="M3.90409 8.4038C3.74222 8.31039 3.53504 8.36571 3.44131 8.52761C3.34793 8.68968 3.4035 8.89688 3.56538 8.99031C3.61866 9.02117 3.67701 9.03586 3.73451 9.03586C3.85146 9.03586 3.96521 8.97512 4.02803 8.86652C4.12151 8.7044 4.06612 8.49725 3.90409 8.4038Z"
                    fill="#444790"
                  />
                  <path
                    d="M3.72864 3.12615C3.78634 3.12615 3.84495 3.11144 3.89848 3.0804C4.06011 2.9865 4.11524 2.7791 4.02129 2.61745C3.92715 2.4556 3.72003 2.40069 3.55833 2.49457C3.39648 2.58847 3.34159 2.79564 3.43553 2.9575C3.49837 3.06569 3.61184 3.12615 3.72864 3.12615Z"
                    fill="#444790"
                  />
                  <path
                    d="M2.31167 4.33143C2.36493 4.36199 2.42311 4.37673 2.48055 4.37673C2.59759 4.37673 2.71158 4.31601 2.77417 4.20717C2.8676 4.04532 2.81199 3.83815 2.65011 3.74472C2.48804 3.65106 2.28089 3.70665 2.18743 3.8687C2.09398 4.03083 2.14962 4.23778 2.31167 4.33143Z"
                    fill="#444790"
                  />
                  <path
                    d="M5.43293 2.66686C5.43318 2.66686 5.43318 2.66686 5.43336 2.66686C5.62047 2.66638 5.7718 2.51458 5.77165 2.32749C5.77112 2.14038 5.61939 1.98905 5.43225 1.9895C5.24512 1.98973 5.09381 2.14156 5.09424 2.32862C5.09447 2.5155 5.24602 2.66686 5.43293 2.66686Z"
                    fill="#444790"
                  />
                  <path
                    d="M2.31488 7.15664C2.15301 7.2503 2.09787 7.45744 2.19183 7.61937C2.25467 7.72769 2.36842 7.78825 2.48522 7.78825C2.5429 7.78825 2.60125 7.77354 2.65478 7.74248C2.81663 7.64885 2.87172 7.4414 2.77781 7.27975C2.68393 7.1179 2.47673 7.06271 2.31488 7.15664Z"
                    fill="#444790"
                  />
                  <path
                    d="M2.36532 5.744C2.36507 5.55687 2.21326 5.40556 2.0262 5.40576C1.83912 5.40599 1.68776 5.5578 1.68799 5.74465C1.68824 5.93176 1.8398 6.08309 2.02668 6.08309H2.02686C2.21402 6.08287 2.36557 5.93104 2.36532 5.744Z"
                    fill="#444790"
                  />
                  <path
                    d="M5.44049 8.81372C5.25368 8.81397 5.10205 8.96555 5.10205 9.15261C5.1023 9.33972 5.25393 9.4913 5.44099 9.49105C5.62805 9.49105 5.77968 9.33922 5.77943 9.15214C5.77946 8.96528 5.6277 8.81372 5.44049 8.81372Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.56389 7.15322C8.40209 7.05959 8.19497 7.11491 8.10122 7.27678C8.00759 7.43863 8.06292 7.64581 8.22478 7.73949C8.27831 7.77034 8.33666 7.78501 8.39408 7.78501C8.51112 7.78501 8.62486 7.72455 8.68745 7.6159C8.78116 7.45405 8.72577 7.24685 8.56389 7.15322Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.85015 5.3938C8.66309 5.39428 8.51171 5.54633 8.51221 5.73339C8.51221 5.73528 8.51221 5.73716 8.51221 5.73877C8.51221 5.7395 8.51221 5.73995 8.51221 5.74042C8.51221 5.92751 8.66374 6.07909 8.85082 6.07909C9.03796 6.07909 9.18952 5.92751 9.18952 5.74042C9.18952 5.73924 9.18952 5.73784 9.18952 5.73646C9.18952 5.73505 9.18952 5.73339 9.18952 5.73179C9.18909 5.5447 9.03721 5.39357 8.85015 5.3938Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.97038 3.07502C7.02358 3.10585 7.08179 3.12031 7.13921 3.12031C7.25624 3.12031 7.37024 3.0596 7.43283 2.95075C7.52629 2.78863 7.47067 2.58148 7.30859 2.48805C7.14647 2.39485 6.93927 2.45044 6.84609 2.61257C6.75271 2.77464 6.80828 2.98184 6.97038 3.07502Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.97615 8.40221C6.8143 8.49584 6.75898 8.70298 6.85286 8.86488C6.91568 8.97323 7.02938 9.03397 7.14623 9.03397C7.20393 9.03397 7.26208 9.01925 7.31554 8.98827C7.47742 8.89461 7.53255 8.68742 7.43892 8.52549C7.34519 8.36361 7.138 8.30827 6.97615 8.40221Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.39076 4.36671C8.44871 4.36671 8.50706 4.35175 8.56077 4.32069C8.72245 4.22655 8.77736 4.01938 8.68323 3.85776C8.58932 3.69588 8.3819 3.64122 8.22027 3.73513C8.0586 3.82901 8.00371 4.03641 8.09764 4.19806C8.16049 4.3062 8.27398 4.36671 8.39076 4.36671Z"
                    fill="#444790"
                  />
                  <path
                    d="M7.56405 5.97697L5.78748 5.8558L5.61015 3.25374C5.60403 3.16423 5.52959 3.09473 5.43982 3.09473C5.35008 3.09473 5.27574 3.16423 5.26957 3.25374L5.09538 5.8086L4.81103 5.78921C4.7731 5.7866 4.73581 5.79987 4.70804 5.82583C4.68033 5.85176 4.66455 5.88808 4.66455 5.9261V6.30162C4.66455 6.33967 4.68033 6.37594 4.70804 6.40188C4.73352 6.42568 4.76706 6.43885 4.80172 6.43885C4.80478 6.43885 4.80789 6.43875 4.81103 6.43854L5.05357 6.42199L5.03606 6.67805C5.0329 6.72523 5.04938 6.77158 5.08163 6.80609C5.11389 6.84065 5.15906 6.86027 5.20636 6.86027H5.67333C5.67353 6.86027 5.67376 6.86027 5.67386 6.86027C5.76806 6.86027 5.84446 6.78387 5.84446 6.68961C5.84446 6.68131 5.82261 6.36945 5.82261 6.36945L7.56413 6.25071C7.63613 6.24579 7.69207 6.18597 7.69207 6.11381C7.69192 6.04171 7.63606 5.98189 7.56405 5.97697Z"
                    fill="#444790"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_519_5681">
                    <rect
                      width="10.0326"
                      height="10.0326"
                      fill="white"
                      transform="translate(0.421875 0.723633)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Bestellzeit:
            </p>
            <p className="col-6 _value">{extractOrderTime(data.orderDate)}</p>
          </div>
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 12"
                fill="none"
              >
                <g clip-path="url(#clip0_519_5672)">
                  <path
                    d="M10.4995 2.85864H1.50251C1.14814 2.85864 0.859863 3.14692 0.859863 3.50129V8.86441C0.859863 9.21878 1.14814 9.50706 1.50251 9.50706H10.4995C10.8539 9.50706 11.1422 9.21878 11.1422 8.86441V3.50129C11.1422 3.1469 10.8539 2.85864 10.4995 2.85864ZM1.50251 3.07285H10.4995C10.7358 3.07285 10.928 3.26499 10.928 3.50129V4.2185H1.07407V3.50129C1.07407 3.26499 1.26621 3.07285 1.50251 3.07285ZM10.4995 9.29283H1.50251C1.26621 9.29283 1.07407 9.10069 1.07407 8.86439V5.67588H10.928V8.86441C10.928 9.10069 10.7358 9.29283 10.4995 9.29283Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.43492 7.06494H1.92938C1.87018 7.06494 1.82227 7.11285 1.82227 7.17206C1.82227 7.23126 1.87018 7.27917 1.92938 7.27917H6.4349C6.49411 7.27917 6.54202 7.23126 6.54202 7.17206C6.54202 7.11285 6.49413 7.06494 6.43492 7.06494Z"
                    fill="#444790"
                  />
                  <path
                    d="M4.26484 7.89185H1.92938C1.87018 7.89185 1.82227 7.93976 1.82227 7.99896C1.82227 8.05816 1.87018 8.10607 1.92938 8.10607H4.26482C4.32402 8.10607 4.37193 8.05816 4.37193 7.99896C4.37193 7.93976 4.32404 7.89185 4.26484 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M10.0733 7.89185H9.825C9.76573 7.89185 9.71777 7.93981 9.71777 7.99908C9.71777 8.05834 9.76573 8.10631 9.825 8.10631H10.0733C10.1325 8.10631 10.1805 8.05834 10.1805 7.99908C10.1805 7.93981 10.1325 7.89185 10.0733 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M9.08354 7.89185H8.83526C8.77599 7.89185 8.72803 7.93981 8.72803 7.99908C8.72803 8.05834 8.77599 8.10631 8.83526 8.10631H9.08354C9.1428 8.10631 9.19077 8.05834 9.19077 7.99908C9.19077 7.93981 9.1428 7.89185 9.08354 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.09377 7.89185H7.84551C7.78624 7.89185 7.73828 7.93981 7.73828 7.99908C7.73828 8.05834 7.78624 8.10631 7.84551 8.10631H8.09379C8.15306 8.10631 8.20102 8.05834 8.20102 7.99908C8.20102 7.93981 8.15304 7.89185 8.09377 7.89185Z"
                    fill="#444790"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_519_5672">
                    <rect
                      width="10.7492"
                      height="10.7492"
                      fill="white"
                      transform="translate(0.626465 0.80835)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Zahlungsmethode:{" "}
            </p>
            <p className="col-6 _value">{data.paymentDetails.method}</p>
          </div>
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 12"
                fill="none"
              >
                <g clip-path="url(#clip0_519_5672)">
                  <path
                    d="M10.4995 2.85864H1.50251C1.14814 2.85864 0.859863 3.14692 0.859863 3.50129V8.86441C0.859863 9.21878 1.14814 9.50706 1.50251 9.50706H10.4995C10.8539 9.50706 11.1422 9.21878 11.1422 8.86441V3.50129C11.1422 3.1469 10.8539 2.85864 10.4995 2.85864ZM1.50251 3.07285H10.4995C10.7358 3.07285 10.928 3.26499 10.928 3.50129V4.2185H1.07407V3.50129C1.07407 3.26499 1.26621 3.07285 1.50251 3.07285ZM10.4995 9.29283H1.50251C1.26621 9.29283 1.07407 9.10069 1.07407 8.86439V5.67588H10.928V8.86441C10.928 9.10069 10.7358 9.29283 10.4995 9.29283Z"
                    fill="#444790"
                  />
                  <path
                    d="M6.43492 7.06494H1.92938C1.87018 7.06494 1.82227 7.11285 1.82227 7.17206C1.82227 7.23126 1.87018 7.27917 1.92938 7.27917H6.4349C6.49411 7.27917 6.54202 7.23126 6.54202 7.17206C6.54202 7.11285 6.49413 7.06494 6.43492 7.06494Z"
                    fill="#444790"
                  />
                  <path
                    d="M4.26484 7.89185H1.92938C1.87018 7.89185 1.82227 7.93976 1.82227 7.99896C1.82227 8.05816 1.87018 8.10607 1.92938 8.10607H4.26482C4.32402 8.10607 4.37193 8.05816 4.37193 7.99896C4.37193 7.93976 4.32404 7.89185 4.26484 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M10.0733 7.89185H9.825C9.76573 7.89185 9.71777 7.93981 9.71777 7.99908C9.71777 8.05834 9.76573 8.10631 9.825 8.10631H10.0733C10.1325 8.10631 10.1805 8.05834 10.1805 7.99908C10.1805 7.93981 10.1325 7.89185 10.0733 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M9.08354 7.89185H8.83526C8.77599 7.89185 8.72803 7.93981 8.72803 7.99908C8.72803 8.05834 8.77599 8.10631 8.83526 8.10631H9.08354C9.1428 8.10631 9.19077 8.05834 9.19077 7.99908C9.19077 7.93981 9.1428 7.89185 9.08354 7.89185Z"
                    fill="#444790"
                  />
                  <path
                    d="M8.09377 7.89185H7.84551C7.78624 7.89185 7.73828 7.93981 7.73828 7.99908C7.73828 8.05834 7.78624 8.10631 7.84551 8.10631H8.09379C8.15306 8.10631 8.20102 8.05834 8.20102 7.99908C8.20102 7.93981 8.15304 7.89185 8.09377 7.89185Z"
                    fill="#444790"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_519_5672">
                    <rect
                      width="10.7492"
                      height="10.7492"
                      fill="white"
                      transform="translate(0.626465 0.80835)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              Zahlungsbetrag:{" "}
            </p>
            <p className="col-6 _value">
              {data.paymentDetails.totalAmount.toFixed(2)} €
            </p>
          </div>
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 16 20"
                fill="none"
              >
                <path
                  d="M0 20C0 15.8095 3.6 12.381 8 12.381C12.4 12.381 16 15.8095 16 20H0ZM8 11.4286C4.7 11.4286 2 8.85714 2 5.71429C2 2.57143 4.7 0 8 0C11.3 0 14 2.57143 14 5.71429C14 8.85714 11.3 11.4286 8 11.4286Z"
                  fill="#444790"
                />
              </svg>{" "}
              Vorname des Kunden:{" "}
            </p>
            <p className="col-6 _value">{data.participantDetails.firstname}</p>
          </div>
          <div className="_detail col-12 row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 16 20"
                fill="none"
              >
                <path
                  d="M0 20C0 15.8095 3.6 12.381 8 12.381C12.4 12.381 16 15.8095 16 20H0ZM8 11.4286C4.7 11.4286 2 8.85714 2 5.71429C2 2.57143 4.7 0 8 0C11.3 0 14 2.57143 14 5.71429C14 8.85714 11.3 11.4286 8 11.4286Z"
                  fill="#444790"
                />
              </svg>{" "}
              Nachname des Kunden:{" "}
            </p>
            <p className="col-6 _value">{data.participantDetails.lastname}</p>
          </div>
          <div className="_detail row d-flex align-items-center">
            <p className="col-6 _label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 10 10"
                fill="none"
              >
                <g clip-path="url(#clip0_519_5636)">
                  <path
                    d="M8.94442 2.45658C8.9094 2.22248 8.79167 2.00868 8.61256 1.85392C8.43345 1.69917 8.20482 1.61371 7.96812 1.61304H1.8889C1.65219 1.61371 1.42356 1.69917 1.24446 1.85392C1.06535 2.00868 0.947614 2.22248 0.912598 2.45658L4.92851 5.05521L8.94442 2.45658Z"
                    fill="#444790"
                  />
                  <path
                    d="M5.07434 5.60113C5.03086 5.62925 4.98019 5.6442 4.92842 5.6442C4.87665 5.6442 4.82598 5.62925 4.7825 5.60113L0.897461 3.08743V7.07136C0.897745 7.33419 1.00228 7.58618 1.18813 7.77203C1.37399 7.95788 1.62597 8.06242 1.88881 8.06271H7.96803C8.23087 8.06242 8.48285 7.95788 8.66871 7.77203C8.85456 7.58618 8.9591 7.33419 8.95938 7.07136V3.08716L5.07434 5.60113Z"
                    fill="#444790"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_519_5636">
                    <rect
                      width="8.59938"
                      height="8.59938"
                      fill="white"
                      transform="translate(0.628906 0.538086)"
                    />
                  </clipPath>
                </defs>
              </svg>{" "}
              E-Mail des Kunden:{" "}
            </p>
            <p className="col-6 text-break _value">
              {data.participantDetails.email}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
