import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import "./EventsDash.css";

export default function StepTwo() {
  const [faqs, setFaqs] = useState([{ id: 1, question: "", answer: "" }]);

  const [nextFaqId, setNextFaqId] = useState(2);

  const addFaq = () => {
    setFaqs([...faqs, { id: nextFaqId, question: "", answer: "" }]);
    setNextFaqId(nextFaqId + 1);
  };

  const deleteFaq = (id) => {
    const updatedFaqs = faqs.filter((faq) => faq.id !== id);
    setFaqs(updatedFaqs);
  };

  return (
    <div className="step_two_container">
      <div className="row align-items-center mb-3">
        <p className="col-md-2 create_event_form_label text-nowrap">
          Event image :
        </p>
        <p className="create_event_form_explanation col-md-10">
          Attract attention with a striking banner. This image is displayed
          directly at the top of the event page .
        </p>
        <div className="col-md-2"></div>
        <div className="col-md-10 d-flex flex-column">
          <div className="create_event_upload_img d-flex flex-column align-items-end">
            <div className="create_event_upload_img_explanation d-flex justify-content-between align-items-center">
              <p className="mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g opacity="0.5" clipPath="url(#clip0_54_14235)">
                    <path
                      d="M0 1.59375V14.4062H16V1.59375H0ZM15.0625 13.4688H0.9375V2.53125H15.0625V13.4688Z"
                      fill="#F6F6F6"
                    />
                    <path
                      d="M5.63672 11.2814H3.78796L6.6908 8.37842L6.02795 7.71558L3.125 10.6184V8.76965H2.1875V12.2189H5.63672V11.2814Z"
                      fill="#F6F6F6"
                    />
                    <path
                      d="M9.97192 8.28455L12.8749 5.38171V7.23047H13.8124V3.78125H10.3632V4.71875H12.2119L9.30908 7.6217L9.97192 8.28455Z"
                      fill="#F6F6F6"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_54_14235">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Recommended Image Size : 2160 * 1080px
              </p>
              <p className="mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M12.0418 15.5834H11.6877C11.2627 15.5834 10.9793 15.3001 10.9793 14.8751C10.9793 14.4501 11.2627 14.1667 11.6877 14.1667H12.0418C12.4668 14.1667 12.7502 13.8834 12.7502 13.4584V6.80008H10.271C9.49183 6.80008 8.85433 6.16258 8.85433 5.38341V2.83341H4.9585C4.5335 2.83341 4.25016 3.11675 4.25016 3.54175V13.4584C4.25016 13.8834 4.5335 14.1667 4.9585 14.1667H5.31266C5.73766 14.1667 6.021 14.4501 6.021 14.8751C6.021 15.3001 5.73766 15.5834 5.31266 15.5834H4.9585C3.75433 15.5834 2.8335 14.6626 2.8335 13.4584V3.54175C2.8335 2.33758 3.75433 1.41675 4.9585 1.41675H9.56266C9.6335 1.41675 9.77516 1.41675 9.846 1.48758C9.91683 1.48758 9.98766 1.55841 10.0585 1.62925L13.9543 5.59591C14.0252 5.66675 14.096 5.73758 14.096 5.80841C14.1668 5.95008 14.1668 6.02092 14.1668 6.09175V13.4584C14.1668 14.6626 13.246 15.5834 12.0418 15.5834ZM10.271 3.89591V5.45425H11.7585L10.271 3.89591Z"
                      fill="white"
                    />
                    <path
                      d="M8.50016 14.8751C8.42933 14.8751 8.28766 14.8751 8.21683 14.8042C8.146 14.8042 8.07516 14.7334 8.00433 14.6626L6.2335 12.8917C5.95016 12.6084 5.95016 12.1834 6.2335 11.9001C6.51683 11.6167 6.94183 11.6167 7.22516 11.9001L7.79183 12.4667V8.50008C7.79183 8.07508 8.07516 7.79175 8.50016 7.79175C8.92516 7.79175 9.2085 8.07508 9.2085 8.50008V12.4667L9.77516 11.9001C10.0585 11.6167 10.4835 11.6167 10.7668 11.9001C11.0502 12.1834 11.0502 12.6084 10.7668 12.8917L8.996 14.6626C8.92516 14.7334 8.85433 14.8042 8.7835 14.8042C8.71266 14.8751 8.571 14.8751 8.50016 14.8751Z"
                      fill="white"
                    />
                  </g>
                </svg>{" "}
                Maximum File Size: 10Mb
              </p>
              <p className="mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_54_14229)">
                    <path
                      d="M12.6875 10.5H11.375V9.1875C11.375 9.07147 11.3289 8.96019 11.2469 8.87814C11.1648 8.79609 11.0535 8.75 10.9375 8.75C10.8215 8.75 10.7102 8.79609 10.6281 8.87814C10.5461 8.96019 10.5 9.07147 10.5 9.1875V10.5H9.1875C9.07147 10.5 8.96019 10.5461 8.87814 10.6281C8.79609 10.7102 8.75 10.8215 8.75 10.9375C8.75 11.0535 8.79609 11.1648 8.87814 11.2469C8.96019 11.3289 9.07147 11.375 9.1875 11.375H10.5V12.6875C10.5 12.8035 10.5461 12.9148 10.6281 12.9969C10.7102 13.0789 10.8215 13.125 10.9375 13.125C11.0535 13.125 11.1648 13.0789 11.2469 12.9969C11.3289 12.9148 11.375 12.8035 11.375 12.6875V11.375H12.6875C12.8035 11.375 12.9148 11.3289 12.9969 11.2469C13.0789 11.1648 13.125 11.0535 13.125 10.9375C13.125 10.8215 13.0789 10.7102 12.9969 10.6281C12.9148 10.5461 12.8035 10.5 12.6875 10.5Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M7.4375 10.5H2.1875C2.07147 10.5 1.96019 10.4539 1.87814 10.3719C1.79609 10.2898 1.75 10.1785 1.75 10.0625V2.1875C1.75 2.07147 1.79609 1.96019 1.87814 1.87814C1.96019 1.79609 2.07147 1.75 2.1875 1.75H10.0625C10.1785 1.75 10.2898 1.79609 10.3719 1.87814C10.4539 1.96019 10.5 2.07147 10.5 2.1875V7.4375C10.5 7.55353 10.5461 7.66481 10.6281 7.74686C10.7102 7.82891 10.8215 7.875 10.9375 7.875C11.0535 7.875 11.1648 7.82891 11.2469 7.74686C11.3289 7.66481 11.375 7.55353 11.375 7.4375V2.1875C11.375 1.8394 11.2367 1.50556 10.9906 1.25942C10.7444 1.01328 10.4106 0.875 10.0625 0.875H2.1875C1.8394 0.875 1.50556 1.01328 1.25942 1.25942C1.01328 1.50556 0.875 1.8394 0.875 2.1875V10.0625C0.875 10.4106 1.01328 10.7444 1.25942 10.9906C1.50556 11.2367 1.8394 11.375 2.1875 11.375H7.4375C7.55353 11.375 7.66481 11.3289 7.74686 11.2469C7.82891 11.1648 7.875 11.0535 7.875 10.9375C7.875 10.8215 7.82891 10.7102 7.74686 10.6281C7.66481 10.5461 7.55353 10.5 7.4375 10.5Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M4.8125 4.8125C5.41656 4.8125 5.90625 4.32281 5.90625 3.71875C5.90625 3.11469 5.41656 2.625 4.8125 2.625C4.20844 2.625 3.71875 3.11469 3.71875 3.71875C3.71875 4.32281 4.20844 4.8125 4.8125 4.8125Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                    <path
                      d="M3.18937 6.25197L2.625 6.82072V9.6251H9.625V6.82072L7.74812 4.93947C7.70745 4.89846 7.65907 4.86592 7.60575 4.84371C7.55244 4.82149 7.49525 4.81006 7.4375 4.81006C7.37975 4.81006 7.32256 4.82149 7.26925 4.84371C7.21593 4.86592 7.16755 4.89846 7.12688 4.93947L4.8125 7.25822L3.81063 6.25197C3.76995 6.21096 3.72157 6.17842 3.66825 6.15621C3.61494 6.13399 3.55776 6.12256 3.5 6.12256C3.44224 6.12256 3.38506 6.13399 3.33175 6.15621C3.27843 6.17842 3.23005 6.21096 3.18937 6.25197Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_54_14229">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                Supported Image File: JPEG or PNG
              </p>
            </div>
            <div className="create_event_upload_img_box d-flex flex-column align-items-center justify-content-center">
              <h6 className="mb-2">Add Banner</h6>
              <svg
                width="82"
                height="82"
                viewBox="0 0 82 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.3"
                  width="82"
                  height="82"
                  rx="41"
                  fill="#BDCAF7"
                />
                <path
                  d="M60.8707 54.5979H57.1074V50.8345C57.1074 50.5018 56.9752 50.1828 56.7399 49.9475C56.5047 49.7122 56.1856 49.5801 55.8529 49.5801C55.5202 49.5801 55.2011 49.7122 54.9658 49.9475C54.7306 50.1828 54.5984 50.5018 54.5984 50.8345V54.5979H50.835C50.5023 54.5979 50.1832 54.7301 49.948 54.9654C49.7127 55.2006 49.5806 55.5197 49.5806 55.8524C49.5806 56.1851 49.7127 56.5042 49.948 56.7394C50.1832 56.9747 50.5023 57.1069 50.835 57.1069H54.5984V60.8703C54.5984 61.203 54.7306 61.522 54.9658 61.7573C55.2011 61.9926 55.5202 62.1247 55.8529 62.1247C56.1856 62.1247 56.5047 61.9926 56.7399 61.7573C56.9752 61.522 57.1074 61.203 57.1074 60.8703V57.1069H60.8707C61.2034 57.1069 61.5225 56.9747 61.7578 56.7394C61.993 56.5042 62.1252 56.1851 62.1252 55.8524C62.1252 55.5197 61.993 55.2006 61.7578 54.9654C61.5225 54.7301 61.2034 54.5979 60.8707 54.5979Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M45.817 54.5982H30.7634C30.4307 54.5982 30.1116 54.466 29.8764 54.2308C29.6411 53.9955 29.5089 53.6765 29.5089 53.3438V30.7634C29.5089 30.4307 29.6411 30.1116 29.8764 29.8764C30.1116 29.6411 30.4307 29.5089 30.7634 29.5089H53.3438C53.6765 29.5089 53.9955 29.6411 54.2308 29.8764C54.466 30.1116 54.5982 30.4307 54.5982 30.7634V45.817C54.5982 46.1497 54.7304 46.4687 54.9656 46.704C55.2009 46.9393 55.52 47.0714 55.8527 47.0714C56.1854 47.0714 56.5045 46.9393 56.7397 46.704C56.975 46.4687 57.1071 46.1497 57.1071 45.817V30.7634C57.1071 29.7653 56.7106 28.808 56.0049 28.1023C55.2991 27.3965 54.3419 27 53.3438 27H30.7634C29.7653 27 28.808 27.3965 28.1023 28.1023C27.3965 28.808 27 29.7653 27 30.7634V53.3438C27 54.3419 27.3965 55.2991 28.1023 56.0049C28.808 56.7106 29.7653 57.1071 30.7634 57.1071H45.817C46.1497 57.1071 46.4687 56.975 46.704 56.7397C46.9393 56.5045 47.0714 56.1854 47.0714 55.8527C47.0714 55.52 46.9393 55.2009 46.704 54.9656C46.4687 54.7304 46.1497 54.5982 45.817 54.5982Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M38.29 38.2899C40.022 38.2899 41.4261 36.8858 41.4261 35.1537C41.4261 33.4217 40.022 32.0176 38.29 32.0176C36.5579 32.0176 35.1538 33.4217 35.1538 35.1537C35.1538 36.8858 36.5579 38.2899 38.29 38.2899Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M33.6363 42.4177L32.0181 44.0485V52.0896H52.0895V44.0485L46.7078 38.6543C46.5912 38.5367 46.4525 38.4434 46.2996 38.3797C46.1467 38.316 45.9828 38.2832 45.8172 38.2832C45.6516 38.2832 45.4876 38.316 45.3347 38.3797C45.1819 38.4434 45.0431 38.5367 44.9265 38.6543L38.2904 45.3029L35.4177 42.4177C35.301 42.3001 35.1623 42.2068 35.0094 42.1431C34.8566 42.0794 34.6926 42.0466 34.527 42.0466C34.3614 42.0466 34.1974 42.0794 34.0446 42.1431C33.8917 42.2068 33.7529 42.3001 33.6363 42.4177Z"
                  fill="#BDCAF7"
                />
              </svg>
              <p className="mt-3">High Quality( JPEG, PNG )</p>
              <button
                className="btn create_event_upload_image_button"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 2.8645C12.9315 2.8645 13.2812 3.21428 13.2812 3.64575V16.052C13.2812 16.4835 12.9315 16.8333 12.5 16.8333C12.0685 16.8333 11.7188 16.4835 11.7188 16.052V3.64575C11.7188 3.21428 12.0685 2.8645 12.5 2.8645Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.83207 11.9483C8.13676 11.6428 8.63139 11.6421 8.93689 11.9468L12.4998 15.5L16.0628 11.9468C16.3683 11.6421 16.8629 11.6428 17.1676 11.9483C17.4723 12.2539 17.4716 12.7485 17.1661 13.0532L13.3439 16.8649C13.3438 16.865 13.344 16.8649 13.3439 16.8649C12.8731 17.3355 12.1308 17.3109 11.6738 16.8824C11.6679 16.8769 11.6622 16.8714 11.6565 16.8657L7.83358 13.0532C7.52808 12.7485 7.52739 12.2539 7.83207 11.9483Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.125 15.6562C3.55648 15.6562 3.90625 16.0061 3.90625 16.4375V18.4063C3.90625 19.598 4.87759 20.5729 6.08333 20.5729H18.9167C20.1224 20.5729 21.0938 19.598 21.0938 18.4063V16.4375C21.0938 16.0061 21.4435 15.6562 21.875 15.6562C22.3065 15.6562 22.6562 16.0061 22.6562 16.4375V18.4063C22.6562 20.4645 20.9818 22.1354 18.9167 22.1354H6.08333C4.01823 22.1354 2.34375 20.4645 2.34375 18.4063V16.4375C2.34375 16.0061 2.69352 15.6562 3.125 15.6562Z"
                    fill="white"
                  />
                </svg>{" "}
                Upload Picture
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <p className="col-md-2 create_event_form_label text-nowrap">Media :</p>
        <p className="create_event_form_explanation col-md-10">
          Use pictures to show what your event is about. You can upload up to 10
          images.
        </p>
        <div className="col-md-2 d-md-block d-none"></div>
        <div className="create_event_form_media_container col-md-10 col-12 d-flex flex-wrap">
          <div className="create_event_form_media my-2 d-flex flex-column align-items-center">
            <h6>Add Picture</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M32.625 27H29.25V23.625C29.25 23.3266 29.1315 23.0405 28.9205 22.8295C28.7095 22.6185 28.4234 22.5 28.125 22.5C27.8266 22.5 27.5405 22.6185 27.3295 22.8295C27.1185 23.0405 27 23.3266 27 23.625V27H23.625C23.3266 27 23.0405 27.1185 22.8295 27.3295C22.6185 27.5405 22.5 27.8266 22.5 28.125C22.5 28.4234 22.6185 28.7095 22.8295 28.9205C23.0405 29.1315 23.3266 29.25 23.625 29.25H27V32.625C27 32.9234 27.1185 33.2095 27.3295 33.4205C27.5405 33.6315 27.8266 33.75 28.125 33.75C28.4234 33.75 28.7095 33.6315 28.9205 33.4205C29.1315 33.2095 29.25 32.9234 29.25 32.625V29.25H32.625C32.9234 29.25 33.2095 29.1315 33.4205 28.9205C33.6315 28.7095 33.75 28.4234 33.75 28.125C33.75 27.8266 33.6315 27.5405 33.4205 27.3295C33.2095 27.1185 32.9234 27 32.625 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M19.125 27H5.625C5.32663 27 5.04048 26.8815 4.8295 26.6705C4.61853 26.4595 4.5 26.1734 4.5 25.875V5.625C4.5 5.32663 4.61853 5.04048 4.8295 4.8295C5.04048 4.61853 5.32663 4.5 5.625 4.5H25.875C26.1734 4.5 26.4595 4.61853 26.6705 4.8295C26.8815 5.04048 27 5.32663 27 5.625V19.125C27 19.4234 27.1185 19.7095 27.3295 19.9205C27.5405 20.1315 27.8266 20.25 28.125 20.25C28.4234 20.25 28.7095 20.1315 28.9205 19.9205C29.1315 19.7095 29.25 19.4234 29.25 19.125V5.625C29.25 4.72989 28.8944 3.87145 28.2615 3.23851C27.6286 2.60558 26.7701 2.25 25.875 2.25H5.625C4.72989 2.25 3.87145 2.60558 3.23851 3.23851C2.60558 3.87145 2.25 4.72989 2.25 5.625V25.875C2.25 26.7701 2.60558 27.6286 3.23851 28.2615C3.87145 28.8944 4.72989 29.25 5.625 29.25H19.125C19.4234 29.25 19.7095 29.1315 19.9205 28.9205C20.1315 28.7095 20.25 28.4234 20.25 28.125C20.25 27.8266 20.1315 27.5405 19.9205 27.3295C19.7095 27.1185 19.4234 27 19.125 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M12.375 12.375C13.9283 12.375 15.1875 11.1158 15.1875 9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 8.0092 9.5625 9.5625C9.5625 11.1158 10.8217 12.375 12.375 12.375Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M8.20125 16.0762L6.75 17.5387V24.7499H24.75V17.5387L19.9237 12.7012C19.8192 12.5957 19.6947 12.512 19.5576 12.4549C19.4206 12.3978 19.2735 12.3684 19.125 12.3684C18.9765 12.3684 18.8294 12.3978 18.6924 12.4549C18.5553 12.512 18.4308 12.5957 18.3263 12.7012L12.375 18.6637L9.79875 16.0762C9.69417 15.9707 9.56974 15.887 9.43265 15.8299C9.29556 15.7728 9.14851 15.7434 9 15.7434C8.85149 15.7434 8.70444 15.7728 8.56735 15.8299C8.43026 15.887 8.30583 15.9707 8.20125 16.0762Z"
                  fill="#BDCAF7"
                />
              </g>
            </svg>
          </div>
          <div className="create_event_form_media my-2 d-flex flex-column align-items-center">
            <h6>Add Picture</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M32.625 27H29.25V23.625C29.25 23.3266 29.1315 23.0405 28.9205 22.8295C28.7095 22.6185 28.4234 22.5 28.125 22.5C27.8266 22.5 27.5405 22.6185 27.3295 22.8295C27.1185 23.0405 27 23.3266 27 23.625V27H23.625C23.3266 27 23.0405 27.1185 22.8295 27.3295C22.6185 27.5405 22.5 27.8266 22.5 28.125C22.5 28.4234 22.6185 28.7095 22.8295 28.9205C23.0405 29.1315 23.3266 29.25 23.625 29.25H27V32.625C27 32.9234 27.1185 33.2095 27.3295 33.4205C27.5405 33.6315 27.8266 33.75 28.125 33.75C28.4234 33.75 28.7095 33.6315 28.9205 33.4205C29.1315 33.2095 29.25 32.9234 29.25 32.625V29.25H32.625C32.9234 29.25 33.2095 29.1315 33.4205 28.9205C33.6315 28.7095 33.75 28.4234 33.75 28.125C33.75 27.8266 33.6315 27.5405 33.4205 27.3295C33.2095 27.1185 32.9234 27 32.625 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M19.125 27H5.625C5.32663 27 5.04048 26.8815 4.8295 26.6705C4.61853 26.4595 4.5 26.1734 4.5 25.875V5.625C4.5 5.32663 4.61853 5.04048 4.8295 4.8295C5.04048 4.61853 5.32663 4.5 5.625 4.5H25.875C26.1734 4.5 26.4595 4.61853 26.6705 4.8295C26.8815 5.04048 27 5.32663 27 5.625V19.125C27 19.4234 27.1185 19.7095 27.3295 19.9205C27.5405 20.1315 27.8266 20.25 28.125 20.25C28.4234 20.25 28.7095 20.1315 28.9205 19.9205C29.1315 19.7095 29.25 19.4234 29.25 19.125V5.625C29.25 4.72989 28.8944 3.87145 28.2615 3.23851C27.6286 2.60558 26.7701 2.25 25.875 2.25H5.625C4.72989 2.25 3.87145 2.60558 3.23851 3.23851C2.60558 3.87145 2.25 4.72989 2.25 5.625V25.875C2.25 26.7701 2.60558 27.6286 3.23851 28.2615C3.87145 28.8944 4.72989 29.25 5.625 29.25H19.125C19.4234 29.25 19.7095 29.1315 19.9205 28.9205C20.1315 28.7095 20.25 28.4234 20.25 28.125C20.25 27.8266 20.1315 27.5405 19.9205 27.3295C19.7095 27.1185 19.4234 27 19.125 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M12.375 12.375C13.9283 12.375 15.1875 11.1158 15.1875 9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 8.0092 9.5625 9.5625C9.5625 11.1158 10.8217 12.375 12.375 12.375Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M8.20125 16.0762L6.75 17.5387V24.7499H24.75V17.5387L19.9237 12.7012C19.8192 12.5957 19.6947 12.512 19.5576 12.4549C19.4206 12.3978 19.2735 12.3684 19.125 12.3684C18.9765 12.3684 18.8294 12.3978 18.6924 12.4549C18.5553 12.512 18.4308 12.5957 18.3263 12.7012L12.375 18.6637L9.79875 16.0762C9.69417 15.9707 9.56974 15.887 9.43265 15.8299C9.29556 15.7728 9.14851 15.7434 9 15.7434C8.85149 15.7434 8.70444 15.7728 8.56735 15.8299C8.43026 15.887 8.30583 15.9707 8.20125 16.0762Z"
                  fill="#BDCAF7"
                />
              </g>
            </svg>
          </div>
          <div className="create_event_form_media my-2 d-flex flex-column align-items-center">
            <h6>Add Picture</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M32.625 27H29.25V23.625C29.25 23.3266 29.1315 23.0405 28.9205 22.8295C28.7095 22.6185 28.4234 22.5 28.125 22.5C27.8266 22.5 27.5405 22.6185 27.3295 22.8295C27.1185 23.0405 27 23.3266 27 23.625V27H23.625C23.3266 27 23.0405 27.1185 22.8295 27.3295C22.6185 27.5405 22.5 27.8266 22.5 28.125C22.5 28.4234 22.6185 28.7095 22.8295 28.9205C23.0405 29.1315 23.3266 29.25 23.625 29.25H27V32.625C27 32.9234 27.1185 33.2095 27.3295 33.4205C27.5405 33.6315 27.8266 33.75 28.125 33.75C28.4234 33.75 28.7095 33.6315 28.9205 33.4205C29.1315 33.2095 29.25 32.9234 29.25 32.625V29.25H32.625C32.9234 29.25 33.2095 29.1315 33.4205 28.9205C33.6315 28.7095 33.75 28.4234 33.75 28.125C33.75 27.8266 33.6315 27.5405 33.4205 27.3295C33.2095 27.1185 32.9234 27 32.625 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M19.125 27H5.625C5.32663 27 5.04048 26.8815 4.8295 26.6705C4.61853 26.4595 4.5 26.1734 4.5 25.875V5.625C4.5 5.32663 4.61853 5.04048 4.8295 4.8295C5.04048 4.61853 5.32663 4.5 5.625 4.5H25.875C26.1734 4.5 26.4595 4.61853 26.6705 4.8295C26.8815 5.04048 27 5.32663 27 5.625V19.125C27 19.4234 27.1185 19.7095 27.3295 19.9205C27.5405 20.1315 27.8266 20.25 28.125 20.25C28.4234 20.25 28.7095 20.1315 28.9205 19.9205C29.1315 19.7095 29.25 19.4234 29.25 19.125V5.625C29.25 4.72989 28.8944 3.87145 28.2615 3.23851C27.6286 2.60558 26.7701 2.25 25.875 2.25H5.625C4.72989 2.25 3.87145 2.60558 3.23851 3.23851C2.60558 3.87145 2.25 4.72989 2.25 5.625V25.875C2.25 26.7701 2.60558 27.6286 3.23851 28.2615C3.87145 28.8944 4.72989 29.25 5.625 29.25H19.125C19.4234 29.25 19.7095 29.1315 19.9205 28.9205C20.1315 28.7095 20.25 28.4234 20.25 28.125C20.25 27.8266 20.1315 27.5405 19.9205 27.3295C19.7095 27.1185 19.4234 27 19.125 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M12.375 12.375C13.9283 12.375 15.1875 11.1158 15.1875 9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 8.0092 9.5625 9.5625C9.5625 11.1158 10.8217 12.375 12.375 12.375Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M8.20125 16.0762L6.75 17.5387V24.7499H24.75V17.5387L19.9237 12.7012C19.8192 12.5957 19.6947 12.512 19.5576 12.4549C19.4206 12.3978 19.2735 12.3684 19.125 12.3684C18.9765 12.3684 18.8294 12.3978 18.6924 12.4549C18.5553 12.512 18.4308 12.5957 18.3263 12.7012L12.375 18.6637L9.79875 16.0762C9.69417 15.9707 9.56974 15.887 9.43265 15.8299C9.29556 15.7728 9.14851 15.7434 9 15.7434C8.85149 15.7434 8.70444 15.7728 8.56735 15.8299C8.43026 15.887 8.30583 15.9707 8.20125 16.0762Z"
                  fill="#BDCAF7"
                />
              </g>
            </svg>
          </div>
          <div className="create_event_form_media my-2 d-flex flex-column align-items-center">
            <h6>Add Picture</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M32.625 27H29.25V23.625C29.25 23.3266 29.1315 23.0405 28.9205 22.8295C28.7095 22.6185 28.4234 22.5 28.125 22.5C27.8266 22.5 27.5405 22.6185 27.3295 22.8295C27.1185 23.0405 27 23.3266 27 23.625V27H23.625C23.3266 27 23.0405 27.1185 22.8295 27.3295C22.6185 27.5405 22.5 27.8266 22.5 28.125C22.5 28.4234 22.6185 28.7095 22.8295 28.9205C23.0405 29.1315 23.3266 29.25 23.625 29.25H27V32.625C27 32.9234 27.1185 33.2095 27.3295 33.4205C27.5405 33.6315 27.8266 33.75 28.125 33.75C28.4234 33.75 28.7095 33.6315 28.9205 33.4205C29.1315 33.2095 29.25 32.9234 29.25 32.625V29.25H32.625C32.9234 29.25 33.2095 29.1315 33.4205 28.9205C33.6315 28.7095 33.75 28.4234 33.75 28.125C33.75 27.8266 33.6315 27.5405 33.4205 27.3295C33.2095 27.1185 32.9234 27 32.625 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M19.125 27H5.625C5.32663 27 5.04048 26.8815 4.8295 26.6705C4.61853 26.4595 4.5 26.1734 4.5 25.875V5.625C4.5 5.32663 4.61853 5.04048 4.8295 4.8295C5.04048 4.61853 5.32663 4.5 5.625 4.5H25.875C26.1734 4.5 26.4595 4.61853 26.6705 4.8295C26.8815 5.04048 27 5.32663 27 5.625V19.125C27 19.4234 27.1185 19.7095 27.3295 19.9205C27.5405 20.1315 27.8266 20.25 28.125 20.25C28.4234 20.25 28.7095 20.1315 28.9205 19.9205C29.1315 19.7095 29.25 19.4234 29.25 19.125V5.625C29.25 4.72989 28.8944 3.87145 28.2615 3.23851C27.6286 2.60558 26.7701 2.25 25.875 2.25H5.625C4.72989 2.25 3.87145 2.60558 3.23851 3.23851C2.60558 3.87145 2.25 4.72989 2.25 5.625V25.875C2.25 26.7701 2.60558 27.6286 3.23851 28.2615C3.87145 28.8944 4.72989 29.25 5.625 29.25H19.125C19.4234 29.25 19.7095 29.1315 19.9205 28.9205C20.1315 28.7095 20.25 28.4234 20.25 28.125C20.25 27.8266 20.1315 27.5405 19.9205 27.3295C19.7095 27.1185 19.4234 27 19.125 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M12.375 12.375C13.9283 12.375 15.1875 11.1158 15.1875 9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 8.0092 9.5625 9.5625C9.5625 11.1158 10.8217 12.375 12.375 12.375Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M8.20125 16.0762L6.75 17.5387V24.7499H24.75V17.5387L19.9237 12.7012C19.8192 12.5957 19.6947 12.512 19.5576 12.4549C19.4206 12.3978 19.2735 12.3684 19.125 12.3684C18.9765 12.3684 18.8294 12.3978 18.6924 12.4549C18.5553 12.512 18.4308 12.5957 18.3263 12.7012L12.375 18.6637L9.79875 16.0762C9.69417 15.9707 9.56974 15.887 9.43265 15.8299C9.29556 15.7728 9.14851 15.7434 9 15.7434C8.85149 15.7434 8.70444 15.7728 8.56735 15.8299C8.43026 15.887 8.30583 15.9707 8.20125 16.0762Z"
                  fill="#BDCAF7"
                />
              </g>
            </svg>
          </div>
          <div className="create_event_form_media my-2 d-flex flex-column align-items-center">
            <h6>Add Picture</h6>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g opacity="0.5">
                <path
                  d="M32.625 27H29.25V23.625C29.25 23.3266 29.1315 23.0405 28.9205 22.8295C28.7095 22.6185 28.4234 22.5 28.125 22.5C27.8266 22.5 27.5405 22.6185 27.3295 22.8295C27.1185 23.0405 27 23.3266 27 23.625V27H23.625C23.3266 27 23.0405 27.1185 22.8295 27.3295C22.6185 27.5405 22.5 27.8266 22.5 28.125C22.5 28.4234 22.6185 28.7095 22.8295 28.9205C23.0405 29.1315 23.3266 29.25 23.625 29.25H27V32.625C27 32.9234 27.1185 33.2095 27.3295 33.4205C27.5405 33.6315 27.8266 33.75 28.125 33.75C28.4234 33.75 28.7095 33.6315 28.9205 33.4205C29.1315 33.2095 29.25 32.9234 29.25 32.625V29.25H32.625C32.9234 29.25 33.2095 29.1315 33.4205 28.9205C33.6315 28.7095 33.75 28.4234 33.75 28.125C33.75 27.8266 33.6315 27.5405 33.4205 27.3295C33.2095 27.1185 32.9234 27 32.625 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M19.125 27H5.625C5.32663 27 5.04048 26.8815 4.8295 26.6705C4.61853 26.4595 4.5 26.1734 4.5 25.875V5.625C4.5 5.32663 4.61853 5.04048 4.8295 4.8295C5.04048 4.61853 5.32663 4.5 5.625 4.5H25.875C26.1734 4.5 26.4595 4.61853 26.6705 4.8295C26.8815 5.04048 27 5.32663 27 5.625V19.125C27 19.4234 27.1185 19.7095 27.3295 19.9205C27.5405 20.1315 27.8266 20.25 28.125 20.25C28.4234 20.25 28.7095 20.1315 28.9205 19.9205C29.1315 19.7095 29.25 19.4234 29.25 19.125V5.625C29.25 4.72989 28.8944 3.87145 28.2615 3.23851C27.6286 2.60558 26.7701 2.25 25.875 2.25H5.625C4.72989 2.25 3.87145 2.60558 3.23851 3.23851C2.60558 3.87145 2.25 4.72989 2.25 5.625V25.875C2.25 26.7701 2.60558 27.6286 3.23851 28.2615C3.87145 28.8944 4.72989 29.25 5.625 29.25H19.125C19.4234 29.25 19.7095 29.1315 19.9205 28.9205C20.1315 28.7095 20.25 28.4234 20.25 28.125C20.25 27.8266 20.1315 27.5405 19.9205 27.3295C19.7095 27.1185 19.4234 27 19.125 27Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M12.375 12.375C13.9283 12.375 15.1875 11.1158 15.1875 9.5625C15.1875 8.0092 13.9283 6.75 12.375 6.75C10.8217 6.75 9.5625 8.0092 9.5625 9.5625C9.5625 11.1158 10.8217 12.375 12.375 12.375Z"
                  fill="#BDCAF7"
                />
                <path
                  d="M8.20125 16.0762L6.75 17.5387V24.7499H24.75V17.5387L19.9237 12.7012C19.8192 12.5957 19.6947 12.512 19.5576 12.4549C19.4206 12.3978 19.2735 12.3684 19.125 12.3684C18.9765 12.3684 18.8294 12.3978 18.6924 12.4549C18.5553 12.512 18.4308 12.5957 18.3263 12.7012L12.375 18.6637L9.79875 16.0762C9.69417 15.9707 9.56974 15.887 9.43265 15.8299C9.29556 15.7728 9.14851 15.7434 9 15.7434C8.85149 15.7434 8.70444 15.7728 8.56735 15.8299C8.43026 15.887 8.30583 15.9707 8.20125 16.0762Z"
                  fill="#BDCAF7"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <p className="col-md-2 create_event_form_label text-nowrap">
          Video Link :
        </p>
        <p className="create_event_form_explanation col-md-10">
          Embed a link to a YouTube or Vimeo video that captures the mood at
          your event. The video is displayed along with your event images.
        </p>
        <div className="col-md-2"></div>
        <div className="col-md-10">
          <Form.Control className="create_event_form_input" type="text" />
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <p className="col-md-2 create_event_form_label text-nowrap">
          Description :
        </p>
        <p className="create_event_form_explanation col-md-10">
          Attract attention with a catchy event description. Here you can
          provide information about the Event, such as the program, sponsors or
          Invited Guestss.
        </p>
        <div className="col-md-2"></div>
        <div className="col-md-10 d-flex flex-column">
          <textarea
            className="form-control create_event_form_description"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <p className="col-md-2 create_event_form_label text-nowrap">FAQs :</p>
        <p className="create_event_form_explanation col-md-10">
          Answer typical questions about the event, such as parking,
          accessibility and refunds.
        </p>
        <div className="col-md-2"></div>
        <div>
          {faqs.map((faq, index) => (
            <div
              className="row align-items-center mb-3 create_event_form_faq_container"
              key={index}
            >
              <div className="col-md-2"></div>
              <div className="col-md-10 create_event_form_faq">
                <button
                  className="btn _delete_button"
                  type="button"
                  onClick={() => deleteFaq(faq.id)}
                  disabled={faqs.length === 1}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_45_12834)">
                      <path
                        d="M7.7533 2.09091C7.7533 1.40527 8.28839 0.904177 8.97403 0.904177H13.9923C14.6777 0.904177 15.2128 1.40527 15.2128 2.09091V3.39066H16.1169V2.09091C16.1169 0.906826 15.1763 0 13.9923 0H8.97403C7.78994 0 6.84912 0.906826 6.84912 2.09091V3.39066H7.7533V2.09091Z"
                        fill="#FF3E6C"
                      />
                      <path
                        d="M6.13724 23H16.8291C17.86 23 18.66 22.0932 18.66 20.9656V7.00739H4.30615V20.9656C4.30615 22.0932 5.10614 23 6.13724 23ZM14.3651 9.16606C14.3651 8.9164 14.5675 8.71398 14.8172 8.71398C15.0669 8.71398 15.2693 8.9164 15.2693 9.16606V19.8467C15.2693 20.0963 15.0669 20.2987 14.8172 20.2987C14.5675 20.2987 14.3651 20.0963 14.3651 19.8467V9.16606ZM11.031 9.16606C11.031 8.9164 11.2334 8.71398 11.4831 8.71398C11.7327 8.71398 11.9351 8.9164 11.9351 9.16606V19.8467C11.9351 20.0963 11.7327 20.2987 11.4831 20.2987C11.2334 20.2987 11.031 20.0963 11.031 19.8467V9.16606ZM7.69682 9.16606C7.69682 8.9164 7.89924 8.71398 8.1489 8.71398C8.39857 8.71398 8.60099 8.9164 8.60099 9.16606V19.8467C8.60099 20.0963 8.39857 20.2987 8.1489 20.2987C7.89924 20.2987 7.69682 20.0963 7.69682 19.8467V9.16606Z"
                        fill="#FF3E6C"
                      />
                      <path
                        d="M3.84262 6.10319H19.1231C19.7474 6.10319 20.2533 5.59723 20.2533 4.97296C20.2533 4.34869 19.7474 3.84274 19.1231 3.84274H3.84262C3.21835 3.84274 2.7124 4.34869 2.7124 4.97296C2.7124 5.59723 3.21835 6.10319 3.84262 6.10319Z"
                        fill="#FF3E6C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_45_12834">
                        <rect width="23" height="23" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <div className="_header">FAQ({index + 1}) :</div>
                <div className="_divider"></div>
                <div className="_inputs row">
                  <p className="col-2 mb-3 _label text-nowrap">Question :</p>
                  <div className="col-10 mb-3">
                    <Form.Control
                      className="create_event_form_input _input"
                      type="text"
                      value={faq.question}
                      onChange={(e) => {
                        const updatedFaqs = faqs.map((item) =>
                          item.id === faq.id
                            ? { ...item, question: e.target.value }
                            : item
                        );
                        setFaqs(updatedFaqs);
                      }}
                    />
                  </div>
                  <p className="col-2 _label text-nowrap">Answer :</p>
                  <div className="col-10">
                    <textarea
                      className="form-control _textarea"
                      rows="3"
                      value={faq.answer}
                      onChange={(e) => {
                        const updatedFaqs = faqs.map((item) =>
                          item.id === faq.id
                            ? { ...item, answer: e.target.value }
                            : item
                        );
                        setFaqs(updatedFaqs);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="row align-items-center mb-3">
            <div className="col-md-2"></div>
            <div className="col-md-10 mt-4 ps-0">
              <button
                className="btn create_event_form_faq_add"
                onClick={addFaq}
              >
                Add new faq{" "}
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    opacity="0.92"
                    cx="26.5"
                    cy="27"
                    rx="26.5"
                    ry="26"
                    fill="#BDCAF7"
                  />
                  <path
                    d="M25.5931 34.2209V20.7337H27.4149V34.2209H25.5931ZM19.7551 28.3828V26.5717H33.2529V28.3828H19.7551Z"
                    fill="#444790"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
